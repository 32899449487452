jQuery(function ($) {

    //--------------------------------------------------
    // Cookie Popup

        if($('#cookie').length) {
            var cookieOptions = $('.js-cookie-options'),
                toggleAll = $('.js-cookies-toggle-all'),
                save = $('.js-cookies-accept');

            $('.js-input-toggle').each(function(){
                // Check if cookie is already enabled
                if ($(this).prop('checked')) {
                    $(this).next('label').find('.js-label-toggle').text('Enabled');
                }
                else {
                    $(this).next('label').find('.js-label-toggle').text('Disabled');
                }

                // Update label on toggle
                $(this).on('change', function(){
                    if ($(this).prop('checked')) {
                        $(this).next('label').find('.js-label-toggle').text('Enabled');
                    }
                    else {
                        $(this).next('label').find('.js-label-toggle').text('Disabled');
                    }
                });
            });

            cookieOptions.on('click', function(e){
                e.preventDefault();
                $('.cookie-details').show();
                $('.cookie-overview .btns').toggleClass('visible');
            });

            toggleAll.on('click', function(e){
                e.preventDefault();
                $('.cookie-row:not(.necessary-cookies)').each(function(i){
                    // Based on the first optional cookies value toggle the rest
                    if ( i === 0) {
                        var row = $(this);
                        var elem = $('.js-label-toggle', row);

                        if (elem.text() == 'Enabled') {
                            $('.cookie-row').find('.js-label-toggle').text('Disabled');
                            $('.cookie-row').find('.js-input-toggle').prop('checked', false);
                        } else {
                            $('.cookie-row').find('.js-label-toggle').text('Enabled');
                            $('.cookie-row').find('.js-input-toggle').prop('checked', true);
                            toggleAll.removeClass('checked');
                        }
                    }
                });
            }); // toggle all

        }; // cookie length

});
