import {createFocusTrap} from 'focus-trap';
import {gsap} from 'gsap';

jQuery(($) => {
  let timerId;
  const timeout = 150;
  let initialised = false;
  let searchVisible = false;
  async function loadScripts() {
    const algoliasearch = await import('algoliasearch/lite');
    const instantsearch = await import('instantsearch.js');
    const {index, searchBox, hits, pagination, configure} = await import(
      'instantsearch.js/es/widgets'
    );
    init({
      algoliasearch,
      instantsearch,
      index,
      searchBox,
      hits,
      pagination,
      configure,
    });
  }
  if (!document.querySelector('#search-trigger')) return;
  document.querySelector('#search-trigger').addEventListener(
    'mouseover',
    (e) => {
      e.preventDefault();
      if (!initialised) {
        loadScripts();
      }
    },
    {once: true},
  );
  document.querySelector('#search-trigger').addEventListener('click', (e) => {
    e.preventDefault();
    if (!initialised) {
      loadScripts();
      toggleSearchVisibility();
    } else if (!searchVisible) {
      // toggling is handled with the focusTrap onDeactivate when visible
      toggleSearchVisibility();
    }
  });

  const searchPage = document.querySelector('.search-page');

  if (searchPage) {
    if (!initialised) {
      loadScripts();
    }
  }

  const searchField = document.querySelector('#search-box');
  const searchWrapper = document.querySelector('.search-box-wrapper');
  const searchTrigger = document.querySelector('#search-trigger');
  const searchClose = document.querySelector('.close-search');

  const focusTrap = createFocusTrap('.search-box-wrapper', {
    clickOutsideDeactivates: true,
    onDeactivate: () => {
      toggleSearchVisibility();
    },
  });

  function outsideClick(event, notelem) {
    let clickedOutsideEl = true;

    if (event.target == notelem || notelem.contains(event.target)) {
      clickedOutsideEl = false;
    }
    if (clickedOutsideEl) {
      return true;
    }
    return false;
  }

  function toggleSearchVisibility() {
    searchVisible = !searchVisible;
    if (searchVisible) {
      searchTrigger.dataset.toggled = '1';
      document.querySelector('.ais-SearchBox-input')?.focus();
      focusTrap.activate();
      gsap.set(searchWrapper, {
        position: 'absolute',
        clip: 'initial',
        whiteSpace: 'initial',
        overflow: 'hidden',
        height: 0,
      });
      gsap.to(searchWrapper, {
        height: 'auto',
        duration: 0.6,
        ease: 'power3.out',
        onComplete: () => {
          searchWrapper.dataset.visible = '1';
        },
      });
    } else {
      gsap.to(searchWrapper, {
        height: 0,
        duration: 0.3,
        ease: 'power3.out',
        onComplete: () => {
          searchTrigger.dataset.toggled = '0';
          searchWrapper.dataset.visible = '0';
        },
      });
    }
  }

  searchClose.addEventListener('click', (e) => {
    e.preventDefault();
    toggleSearchVisibility();
  });

  if (!searchPage) {
    window.addEventListener('keypress', (e) => {
      if (searchVisible && e.keyCode == '27') {
        document.querySelector('#hits').dataset.show = '0';
      }
    });
  }

  function init(fns) {
    const {
      algoliasearch,
      instantsearch,
      searchBox,
      index,
      hits,
      pagination,
      configure,
    } = fns;
    const searchClient = algoliasearch.default(
      '0JIZOCPQB1',
      'f446c9efada33fa663a7039c057f610c',
    );

    const searchIndex = 'specialist_units';
    const search = instantsearch.default({
      indexName: searchIndex,
      routing: false,
      searchClient,
      insights: true,
      onStateChange({uiState, setUiState}) {
        setUiState(uiState);
      },
      searchFunction(helper) {
        if (helper.state.query) {
          helper.search();
        }
      },
    });
    initialised = true;

    const searchPage = '/search/';

    const searchResultsPhoto = (hit, {html}) =>
      html` <a
        href="${hit.url}"
        class="ais-search-result-link-wrapper"
        data-insights-object-id="${hit.objectID}"
        data-insights-position="${hit.__position}"
        data-insights-query-id="${hit.__queryID}"
      >
        <div class="ais-search-result-body">
          <h3>${hit.title}</h3>
          <p>${hit.excerpt}</p>
        </div>
        <div class="ais-search-result-image">
          <img src="${hit.photo}" />
        </div>
      </a>`;

    const searchResultsClinician = (hit, {html}) =>
      html` <a
        href="/specialists/${hit.username}"
        class="ais-search-result-link-wrapper"
        data-insights-object-id="${hit.objectID}"
        data-insights-position="${hit.__position}"
        data-insights-query-id="${hit.__queryID}"
      >
        <div class="ais-search-result-body">
          <h3>${hit.title}</h3>
          <p class="ais-search-result-body__subtitle">${hit.job_title}</p>
          <p>${hit.excerpt}</p>
        </div>
        <div class="ais-search-result-image">
          <img src="${hit.photo}" />
        </div>
      </a>`;

    const searchResultsIcon = (hit, {html}) =>
      html` <a
        href="${hit.url}"
        class="ais-search-result-link-wrapper"
        data-insights-object-id="${hit.objectID}"
        data-insights-position="${hit.__position}"
        data-insights-query-id="${hit.__queryID}"
      >
        <div class="ais-search-result-body">
          <h3>${hit.title}</h3>
          <p>${hit.excerpt}</p>
        </div>
        <div class="ais-search-result-image">
          <span
            ><svg><use xlink:href="/public/svg/sprite.svg#${hit.icon}" /></svg
          ></span>
        </div>
      </a>`;

    const searchResultsPage = (hit, {html}) =>
      html`<a
        href="${hit.url}"
        class="ais-search-result-link-wrapper"
        data-insights-object-id="${hit.objectID}"
        data-insights-position="${hit.__position}"
        data-insights-query-id="${hit.__queryID}"
      >
        <div class="ais-search-result-body">
          <h3>${hit.title}</h3>
          <p>${hit.excerpt}</p>
        </div>
        <div class="ais-search-result-image">
          <span
            ><svg><use xlink:href="/public/svg/sprite.svg#information" /></svg
          ></span>
        </div>
      </a>`;

    search.addWidgets([
      configure({
        hitsPerPage: 3,
        clickAnalytics: true,
      }),
      searchBox({
        container: searchField,
        placeholder: 'Search',
        autofocus: searchPage,
        showReset: true,
        showSubmit: true,
        showLoadingIndicator: true,
        cssClasses: {
          root: 'ais-SearchBox',
          form: 'ais-SearchBox-form',
          input: 'ais-SearchBox-input fui-input',
          submit: 'ais-SearchBox-submit',
          reset: 'ais-SearchBox-reset',
          loadingIcon: 'ais-SearchBox-loading',
        },
        reset() {
          document.querySelector('.search-results-container').dataset.show =
            '0';
        },
        queryHook(query, refine) {
          clearTimeout(timerId);
          timerId = setTimeout(() => refine(query), timeout);
          document.querySelector('.search-results-container').dataset.show =
            query.length == 0 ? '0' : '1';
        },
      }),
      hits({
        container: '#hits-units',
        templates: {
          empty: 'No specialist units match your search',
          item: searchResultsIcon,
        },
      }),
      pagination({
        container: '#pagination-units',
        showFirst: false,
        showLast: false,
        scrollTo: false,
      }),
      index({indexName: 'specialist_treatments'}).addWidgets([
        hits({
          container: '#hits-treatments',
          templates: {
            empty: 'No treatments match your search',
            item: searchResultsIcon,
          },
        }),
        pagination({
          container: '#pagination-treatments',
          showFirst: false,
          showLast: false,
          scrollTo: false,
        }),
      ]),
      index({indexName: 'news'}).addWidgets([
        hits({
          container: '#hits-news',
          templates: {
            empty: 'No news articles match your search',
            item: searchResultsPhoto,
          },
        }),
        pagination({
          container: '#pagination-news',
          showFirst: false,
          showLast: false,
          scrollTo: false,
        }),
      ]),
      index({indexName: 'pages'}).addWidgets([
        hits({
          container: '#hits-pages',
          templates: {
            empty: 'No information pages match your search',
            item: searchResultsPage,
          },
        }),
        pagination({
          container: '#pagination-pages',
          showFirst: false,
          showLast: false,
          scrollTo: false,
        }),
      ]),
      index({indexName: 'events'}).addWidgets([
        hits({
          container: '#hits-events',
          templates: {
            empty: 'No events match your search',
            item: searchResultsPhoto,
          },
        }),
        pagination({
          container: '#pagination-events',
          showFirst: false,
          showLast: false,
          scrollTo: false,
        }),
      ]),
      index({indexName: 'clinicians'}).addWidgets([
        hits({
          container: '#hits-clinicians',
          templates: {
            empty: 'No clinicians match your search',
            item: searchResultsClinician,
          },
        }),
        pagination({
          container: '#pagination-clinicians',
          showFirst: false,
          showLast: false,
          scrollTo: false,
        }),
      ]),
    ]);

    search.start();
    search.refresh();

    // const focusElement = function (el) {
    //   el.dataset.focussed = true;
    //   const link = el.querySelector('a') || el;
    //   if (link) link.focus();
    // };

    // const unFocusElement = function (el) {
    //   el.dataset.focussed = false;
    //   const link = el.querySelector('a');
    //   if (link) link.blur();
    // };

    // document
    //   .querySelector('#hits-container')
    //   .addEventListener('mouseenter', () => {
    //     const hits = document.querySelectorAll('.ais-Hits-item');
    //     hits.forEach((h) => unFocusElement(h));
    //   });

    // window.addEventListener('keydown', (e) => handleKeyboard(e));

    // const handleKeyboard = function (e) {
    //   if (e.keyCode === 40 || e.keyCode === 38) {
    //     if (
    //       e.target.classList.contains('ais-SearchBox-form') ||
    //       e.target.classList.contains('button__orange') ||
    //       e.target.classList.contains('ais-search-result-link-wrapper') ||
    //       e.target.classList.contains('ais-SearchBox-input')
    //     ) {
    //       e.preventDefault();
    //     }
    //   }
    //   // 40 is key down
    //   if (e.keyCode === 40) {
    //     const hits = document.querySelectorAll('.ais-Hits-item');
    //     if (!hits.length) return;
    //     focusTrap.activate();
    //     const totalItems = hits.length;
    //     const current = document.querySelector(
    //       '.ais-Hits-item[data-focussed="true"',
    //     );
    //     if (current) {
    //       unFocusElement(current);
    //       const next = current.nextSibling;
    //       if (next) {
    //         focusElement(next);
    //       } else {
    //         focusElement(document.querySelector('#show-more'));
    //       }
    //     } else {
    //       focusElement(hits[0]);
    //     }
    //   }
    //   if (e.keyCode === 38) {
    //     const hits = document.querySelectorAll('.ais-Hits-item');
    //     if (!hits.length) return;
    //     const totalItems = hits.length;
    //     const current = document.querySelector(
    //       '.ais-Hits-item[data-focussed="true"',
    //     );
    //     if (current) {
    //       unFocusElement(current);
    //       const prev = current.previousSibling;
    //       if (prev) {
    //         focusElement(prev);
    //       } else {
    //         focusElement(document.querySelector('.ais-SearchBox-input'));
    //       }
    //     } else {
    //       focusElement(document.querySelector('.ais-SearchBox-input'));
    //     }
    //   }
    // };
  }
});
