import 'devbridge-autocomplete';
//--------------------------------------------------
// Global

jQuery(function ($) {
  //--------------------------------------------------
  // Form helpers

  $('input:radio').each(function () {
    var parent = $(this).parent('div');
    $(this).on('click', function () {
      $('label', parent).removeClass('checked');
      $(this).next('label').addClass('checked');
    });
  });

  $('input:checkbox').each(function () {
    $(this).on('click', function () {
      $(this).next('label').toggleClass('checked');
    });
  });

  //--------------------------------------------------
  // Global search

  $('#global-search').autocomplete({
    serviceUrl: '/search.json',
    appendTo: $('.js-search-wrapper'),
    onSelect: function (suggestion) {
      window.location.href = suggestion.url;
    },
    transformResult: function (response) {
      var globals = JSON.parse(response).meta.globals;
      var elements = JSON.parse(response).data;

      var data = globals.concat(elements);

      // if we want to add search results mapping, we can do it here??
      return {
        suggestions: $.map(data, function (dataItem) {
          return { value: dataItem.title, url: dataItem.url };
        }),
      };
    },
  });

  //--------------------------------------------------
  // Attach 'close' button and add functionality on mobile

  function mobileSearchClose(x) {
    var elem = $('.search-expandable .site-search');
    if (x.matches) {
      // If media query matches
      elem.prepend(
        '<a href="#" class="close" data-target="search"><span class="off-screen">Close search</span></div>',
      );
      $('.close', elem).on('click', function (e) {
        e.preventDefault();
        $('body').removeClass('js-open-toolbar');
        $('.search-trigger').removeClass('js-open');
        $('#search').hide();
      });
      $('.search-trigger').addClass('search-overlay');
    } else {
      $('.close', elem).remove();
      $('.search-trigger').removeClass('search-overlay');
    }
    $('.search-trigger').removeClass('search-overlay');
  }

  var x = window.matchMedia('(max-width: 760px)');
  mobileSearchClose(x); // Call listener function at run time
  x.addListener(mobileSearchClose); // Attach listener function on state changes

  //--------------------------------------------------
  // Expandable Content

  var navVisible = false;
  $('#toolbar').css('transform', 'translateX(100%)');
  $('#toolbar').css('visibility', 'hidden');
  function toggleNav() {
    navVisible = !navVisible;
    if (navVisible) {
      $('body').attr('data-nav-open', '1');
      $('#toolbar').css('visibility', 'visible');
      $('#toolbar').css('transform', 'translateX(0)');
    } else {
      $('body').attr('data-nav-open', '0');
      $('#toolbar').css('transform', 'translateX(100%)');
    }
  }

  $('.mobile-nav-trigger').on('click', toggleNav);

  $('.js-trigger').on('click', function (e) {
    e.preventDefault();
    var trigger = $(this);
    var open = 'js-open';
    var openToolbar = 'js-open-toolbar';
    var focus = $(this).attr('href');

    //--------------------------------------------------
    // Target a specific container
    // Otherwise target next expandable

    if (trigger.data('target')) {
      var target = $(this).data('target');
      var expandable = $('#' + target);
    } else {
      var expandable = $(this).next('.js-expandable');
    }

    //--------------------------------------------------
    // Show / Hide Expandable

    if (!$(trigger).hasClass(open)) {
      $(expandable).show();
      $(trigger).addClass(open);

      if ($(trigger).hasClass('search-overlay')) {
        $('body').addClass(openToolbar);
      }

      // Focus on the target element
      if (focus.match('^#')) {
        $(focus).focus();
      }

      //--------------------------------------------------
      // Hide all except this

      $('.js-expandable').not(expandable).hide();
      $('.js-trigger').not(trigger).removeClass(open);
    } else {
      $(expandable).hide();
      $(trigger).removeClass(open);
      $('body').removeClass(openToolbar);

      // Unfocus the target element
      if (focus.match('^#')) {
        $(focus).blur();
      }
    }

    //--------------------------------------------------
    // Hide search on escape

    $(document).on('keydown', function (e) {
      if (e.keyCode === 27) {
        // ESC
        $(expandable).hide();
        $(trigger).removeClass(open);
        $('body').removeClass(openToolbar);
        $(focus).blur();
      }
    });
  });

  //--------------------------------------------------
  // Scroll to anchor

  if ($('#cookiesManagement').length == 0) {
    // Checking that the cookies management tab feature is not present. Seems to be a bug where cookie management tabs where auto scrolling to top of page when interacted with

    $('a[href*="#"]')
      .not('[href="#"],.sr-skip,.search-trigger')
      .on('click', function (event) {
        if (
          location.pathname.replace(/^\//, '') ==
            this.pathname.replace(/^\//, '') &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash);
          target = target.length
            ? target
            : $('[name=' + this.hash.slice(1) + ']');

          if (target.length) {
            event.preventDefault();
            $('html, body').animate(
              {
                scrollTop: $(target).offset().top,
              },
              1000,
              function () {
                var $target = $(target);
                $target.focus();
                if ($target.is(':focus')) {
                  // Checking if the target was focused
                  return false;
                } else {
                  $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                }
              },
            );
          }
        }
      });
  }
});
